<template>
  <div
    class="page-box box"
    @scroll="getScroll($event)"
  >
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh"
    >
      <van-sticky :offset-top="0">
        <van-tabs
          v-model="activeTab"
          calss="tab-out-box"
          swipeable
          color="#51B464"
          title-inactive-color="#262626"
          title-active-color="#51B464"
          @change="loadList"
        >
          <template v-for="item in tabList">
            <van-tab
              :key="item.key"
              :title="item.name"
            >
            </van-tab>
          </template>
        </van-tabs>
      </van-sticky>

      <!-- 列表 -->
      <div
        class="order-list-box box"
        style="padding: 0 8px;"
      >
        <template v-for="item in orderList">
          <div
            :key="item.orderNo"
            class="box"
          >
            <div class="tab-in-box">
              <div class="border-x"></div>
              <div class="tab-item-box">
                <!-- 状态按钮 -->
                <div
                  v-if="item.orderStatus == 1"
                  class="status-btn"
                  style="color: #477BFF;"
                >
                  审核中
                </div>
                <div
                  v-if="item.orderStatus == 2"
                  class="status-btn"
                >
                  审核拒绝
                </div>
                <div
                  v-if="item.orderStatus == 3"
                  class="status-btn"
                  style="color:#D5423E;"
                >
                  审核退回
                </div>
                <div
                  v-if="item.orderStatus == 4"
                  class="status-btn"
                >
                  待签署
                </div>
                <div
                  v-if="item.orderStatus == 5"
                  class="status-btn"
                  style="color: #FFAC08;"
                >
                  等待放款
                </div>
                <div
                  v-if="item.orderStatus == 6"
                  class="status-btn"
                  :class="'order-status' + item.orderStatus"
                >
                  还款中
                </div>
                <div
                  v-if="item.orderStatus == 7"
                  class="status-btn"
                  style="color: #949494"
                >
                  已结清
                </div>
                <div
                  v-if="item.orderStatus == 8"
                  class="status-btn"
                  style="color: #949494"
                >
                  已取消
                </div>
                <div class="text-a title">
                  {{ item.orderNo }}
                </div>
                <div
                  v-if="item.orderStatus == 6 && item.overdueTime"
                  class="pass-time box"
                >
                  <div class="tag-item">
                    已逾期{{ item.overdueTime }}天
                  </div>
                </div>
                <div class="text-b text">
                  <van-row>
                    <van-col span="6">
                      校区名称:
                    </van-col>
                    <van-col span="18">
                      {{ item.schoolName || '--' }}
                    </van-col>
                  </van-row>
                </div>
                <div class="text-c text">
                  <van-row>
                    <van-col span="6">
                      课程名称:
                    </van-col>
                    <van-col span="18">
                      {{ item.courseName || '--' }}
                    </van-col>
                  </van-row>
                </div>
                <div class="text-d text">
                  <van-row>
                    <van-col span="6">
                      家长姓名:
                    </van-col>
                    <van-col span="18">
                      {{ item.userName || '--' }}
                    </van-col>
                  </van-row>
                </div>
                <div class="text-e text">
                  <van-row>
                    <van-col span="6">
                      学员姓名:
                    </van-col>
                    <van-col span="18">
                      {{ item.childName || '--' }}
                    </van-col>
                  </van-row>
                </div>
                <van-divider @divider-margin="5" />

                <div
                  v-if="!(item.orderStatus == 5 || item.orderStatus == 8)"
                  class="order-status-box box"
                >
                  <div class="text-a">
                    <van-row>
                      <van-col span="6">
                        课程金额:
                      </van-col>
                      <van-col span="18">
                        {{ changePriceNumber(item.totalAmount) }}元
                      </van-col>
                    </van-row> 
                  </div>
                  <div
                    v-if="item.percentAmount"
                    class="text-b text"
                  >
                    <van-row>
                      <van-col span="6">
                        首付金额:
                      </van-col>
                      <van-col span="18">
                        {{ changePriceNumber(item.percentAmount) }}元
                      </van-col>
                    </van-row>
                  </div>
                  <div class="text-c text">
                    <van-row>
                      <van-col span="6">
                        分期金额:
                      </van-col>
                      <van-col span="18">
                        {{ changePriceNumber(item.amount) }}元
                      </van-col>
                    </van-row>
                  </div>
                  <div class="text-d text">
                    <van-row>
                      <van-col span="6">
                        分期方案:
                      </van-col>
                      <van-col span="18">
                        {{ item.periodInfo || '--' }}
                      </van-col>
                    </van-row>
                  </div>
                  <!-- 按钮 -->
                  <div class="btn-box box">
                    <van-button
                      v-if="item.orderStatus == 1"
                      class="_btn"
                      color="#51B464"
                      type="info"
                      @click="actionBtn('cancelOrder', item)"
                    >
                      取消订单
                    </van-button>
                    <van-button
                      v-if="item.orderStatus == 4"
                      class="_btn"
                      color="#51B464"
                      type="info"
                      @click="actionBtn('goSign', item)"
                    >
                      去签署
                    </van-button>
                    <van-button
                      v-if="item.orderStatus == 3"
                      class="_btn"
                      color="#51B464"
                      plain
                      style="margin-right: 10px;"
                      @click="actionBtn('cancelOrder', item)"
                    >
                      取消订单
                    </van-button>
                    <van-button
                      v-if="item.orderStatus == 3"
                      class="_btn"
                      color="#51B464"
                      @click="actionBtn('editOrder', item)"
                    >
                      修改分期信息
                    </van-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div
          v-show="loading"
          class="end-text box"
        >
          加载中...
        </div>
        <div
          v-show="finished"
          class="end-text box"
        >
          没有更多了
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
export default {
  name: 'OrderIndex',
  components: {
  },
  data () {
    return {
      activeTab: 0,
      tabList: [
        {name: '全部', key: 0},
        {name: '审核中', key: 1},
        {name: '审核拒绝', key: 2},
        {name: '审核退回', key: 3},
        {name: '待签署', key: 4},
        {name: '等待放款', key: 5},
        {name: '还款中', key: 6},
        {name: '已结清', key: 7},
        {name: '已取消', key: 8},
      ],
      pageType: 0,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      finished: false,
      isLoading: false,
      orderList: []
    }
  },
  computed: {
  },
  created () {
    this.loadList()
  },
  methods: {
    async onRefresh () {
      this.loadList()
      this.isLoading = false
    },
    loadList () {
      this.pageNo = 1
      this.orderList = []
      this.getOrderList()
    },
    async getOrderList () {
      let vm = this
      console.log('进入', this.pageNo)
      if (!this.loading) {
        this.loading = true;
        let data = {
          pageNo: this.pageNo,
          pageSize: this.$deepClone(this.pageSize),
          type: this.pageType,
          orderStatus: this.activeTab
        }
        this.pageNo ++
        try {
          let res = await this.$http.orderApi.orderList(data)
          console.log(res, 'list')
          if (res.code === 200) {
            console.log(res, 'res')
            let arr = this.$deepClone(this.orderList)
            for (let i of res.msg.data || []) {
              arr.push(i)
            }
            this.orderList = arr
            this.total = res.msg.total
            console.log(this.orderList.length, res.msg.total)
            this.finished = this.orderList.length >= res.msg.total
          }
          this.loading = false
        } catch (e) {
          vm.loading = false
        }
      }
    },
    // 处理滚动条滚到底部加载数据
    async getScroll (event) {
      let nScrollHight = 0
      let nScrollTop = 0
      let nDivHight = event.target.offsetHeight // 视口的高度
      nScrollHight = event.target.scrollHeight // 滚动高度
      nScrollTop = event.target.scrollTop // 滚动最上面的距离
      if (nScrollTop + nDivHight + 10 >= nScrollHight) { // 解决不同分辨率下scrollTop的差异
        console.log(456, this.pageNo, this.pageNo * this.pageSize, this.total)
        if ((this.pageNo - 1) * this.pageSize <= this.total) {
          await this.getOrderList()
        }
      }
    },
    // 操作按钮
    async actionBtn (msg, item) {
      if (msg === 'cancelOrder') {
        this.cancelOrder(item)
      } else if (msg === 'editOrder') {
        this.$router.push('/order/editInfo?id=' + item.orderNo)
      } else if (msg === 'goSign') {
        let res = await this.$http.orderApi.orderBankCheck({id: item.orderNo})
        if (res.code === 200) {
          window.localStorage.setItem('pdfUrl', item.contractUrl || '')
          window.localStorage.setItem('pdfOrder', JSON.stringify(item))
          // 重新绑卡
          if (res.msg.bind == 2) {
            this.$router.push('/order/periodAddBank')
          } else {
            if (item.loanType == 2 && item.contractUrl) {
              // 去签署
              window.location.href = item.contractUrl
            } else {
              this.$router.push('/order/sign?id=' + item.orderNo)
            }
          }
        }
      }
    },
    // 取消订单
    cancelOrder (item) {
      // let vm = this
      this.$dialog.confirm({
        title: '取消订单',
        message: '确认取消此订单？',
      })
      .then(() => {
        // on confirm
        this.$http.orderApi.orderCancel({id: item.orderNo}).then((res) => {
          if (res.code === 200) {
            this.$toast.success('取消成功')
            item.orderStatus = 8
          }
        })
      })
      .catch(() => {
        // on cancel
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 10px;
  .tab-out-box {
    /deep/ .van-tab__text {
      font-size:15px;
    }
  }
  .tab-in-box {
    padding: 10px;
    position: relative;
    .border-x {
      width: 100%;
      height: 1px;
      background: #D9D9D9;
      z-index: 99;
      position: absolute;
      top: -1px;
      left: 0;
    }
    .tab-item-box {
      padding: 16px 8px 8px;
      width: 100%;
      margin: 0 auto;
      border-radius: 5px;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
      color: #646464;
      position: relative;
      .status-btn {
        position: absolute;
        right: 4px;
        top: 12px;
        font-size:14px;
        line-height:24px;
        display: inline-block;
        padding: 4px;
      }
      .order-status-box {
        background: #FFF7EE;
        padding: 8px 10px;
        font-size: 14px;
      }
      .title {
        color:#262626;
        font-weight: bold;
        padding-bottom: 10px;
        line-height: 24px;
      }
      .text {
        line-height: 24px;
        margin-top: 10px;
      }
    }

  }
  // 还款中
  .order-status6 {
    color: #62C777;
  }
  .pass-time {
    .tag-item {
      float: right;
      padding: 0 3px;
      line-height: 18px;
      height: 18px;
      overflow: hidden;
      border-radius: 2px;
      background: #FE8152;
      color: #fff;
      font-size: xx-small;
      font-size: 10px;
    }
  }
  .btn-box {
    text-align: center;
    padding-top: 6px;
    ._btn {
      height: 30px;
      border-radius: 3px;
    }
  }
}
</style>
